<template>
  <div class="background">
    <div class="top">
      <h2>一些文案一些文案</h2>
      <p>
        一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案一些文案
      </p>
      <div class="button">
        <mt-button :text="`立即咨询`" :height="`64`" :radius="`20`"></mt-button>
      </div>
    </div>
    <HangyeTongdian></HangyeTongdian>
    <DigitalTwin></DigitalTwin>
    <MtAdvantage></MtAdvantage>
  </div>
</template>

<script>
import HangyeTongdian from "./hangye-tongdian/index.vue";
import DigitalTwin from "./digital-twin/index.vue";
import MtAdvantage from "./mt-advantage/index.vue";
export default {
  components: { HangyeTongdian, DigitalTwin, MtAdvantage },
};
</script>

<style lang="scss" scoped>
.background {
  width: 100vw;
  .top {
    width: 100vw;
    height: calc(100vh - 3.125vw);
    background-image: url("../../../../assets/background/top-bakground.png");
    background-size: 100% 100%;
    padding-top: 196px;
    padding-left: 400px;
    color: #ffffff;
    h2 {
      font-weight: 700;
      font-size: 64px;
      line-height: 56px;
    }
    p {
      font-weight: 400;
      font-size: 22px;
      line-height: 44px;
      width: 1121px;
      margin-top: 46px;
    }
    .button {
      margin-top: 53px;
    }
  }
}
</style>
